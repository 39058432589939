var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-2"},[_c('div',{staticClass:"justify-content-between d-flex f-14"},[_c('p',[_vm._v("เลขที่สัญญา :")]),_c('p',{staticClass:"f-14 f-medium"},[_vm._v(_vm._s(_vm.displayContract))])]),(_vm.contract.collectionStatusUnits == 'C')?_c('div',{staticClass:"h-100"},[_c('hr'),_c('diV',{staticClass:"text-center f-orange f-14 py-4"},[_vm._v(" สัญญาสินเชื่อของท่านได้ปิดบัญชีเรียบร้อยแล้ว ")])],1):(
      _vm.contract.collectionStatusUnits == 'L' ||
      _vm.contract.collectionStatusUnits == 'SP'
    )?_c('div',{staticClass:"h-100"},[_c('hr'),_c('diV',{staticClass:"text-center f-orange f-14 my-5"},[_vm._v(" จำนวนที่ท่านต้องชำระขึ้นอยู่กับจำนวนเงินตามที่ระบุในสัญญาสินเชื่อของท่านหรือเป็นไปตามที่ท่านได้ตกลงกับเจ้าหน้าที่ไว้ ")])],1):(
      _vm.contract.contractType == 'HP' ||
      _vm.contract.contractType == 'FL' ||
      _vm.contract.contractType == 'PL' ||
      _vm.contract.contractType == 'TL'
    )?_c('div',[_c('b-row',{staticClass:"mt-2 0"},[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"card-custom d-flex justify-content-between px-1 py-1"},[_c('div',{},[_c('p',{staticClass:"f-14"},[_vm._v(" ค่างวดคงเหลือทั้งสัญญา "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])]),_c('p',{staticClass:"f-12 f-gray"},[_vm._v(" กำหนดชำระงวดสุดท้าย "+_vm._s(_vm.contractInfo.endDueDateDisplay)+" ")])]),_c('p',{staticClass:"f-medium f-14 d-flex mt-4"},[_vm._v(" "+_vm._s(_vm.contractInfo.totalOutstandingBalance)+" ")])])]),_c('b-col',{staticClass:"mt-2 px-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"card-custom d-flex justify-content-between px-1 py-1"},[(
              _vm.contract.contractType === 'HP' || _vm.contract.contractType === 'FL'
            )?_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v(" ค่างวดค้างชำระ "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])]),_c('p',{staticClass:"f-12 f-gray"},[_vm._v(" ค้างชำระตั้งแต่ "+_vm._s(_vm.contractInfo.overdueDueDateDisplay)+" จำนวน "+_vm._s(_vm.contractInfo.overduePeriod)+" งวด ")])]):(
              _vm.contract.contractType === 'PL' || _vm.contract.contractType === 'TL'
            )?_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v(" ค่างวดที่ต้องชำระ "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])]),_c('p',{staticClass:"f-12 f-gray"},[_vm._v(" วันที่ครบกำหนด "+_vm._s(_vm.contractInfo.dueDateDisplay)+" ")])]):_vm._e(),_c('p',{staticClass:"f-medium f-14 f-orange d-flex mt-4"},[_vm._v(" "+_vm._s(_vm.contract.contractType === "HP" || _vm.contract.contractType === "FL" ? _vm.contractInfo.overdueAmount : _vm.contractInfo.installmentAmount)+" ")])])]),(
          _vm.contract.contractType === 'HP' || _vm.contract.contractType === 'FL'
        )?_c('b-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mt-2 px-1 py-1 card-custom"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v(" ค่างวดถัดไป "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])]),_c('p',{staticClass:"f-12 f-gray"},[_vm._v(" กำหนดชำระในวันที่ "+_vm._s(_vm.contractInfo.nextPaymentDueDateDisplay)+" ")])]),_c('p',{staticClass:"f-medium f-14 f-orange d-flex mt-4"},[_vm._v(" "+_vm._s(_vm.contractInfo.nextPaymentAmount)+" ")])])]):_vm._e(),(_vm.contractInfo.displayPenalty)?_c('b-col',{staticClass:"mt-2 px-1 pr-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"card-custom p-1"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v(" ค่าเบี้ยปรับ "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])])]),_c('p',{staticClass:"f-medium f-14 f-orange text-end"},[_vm._v(" "+_vm._s(_vm.contractInfo.penaltyAmt)+" ")])])]):_vm._e(),(_vm.contractInfo.displayCollective)?_c('b-col',{staticClass:"mt-2 px-1 pl-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"card-custom p-1"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v(" ค่าติดตามทวงถาม "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])])]),_c('p',{staticClass:"f-medium f-14 f-orange text-end"},[_vm._v(" "+_vm._s(_vm.contractInfo.callCharge)+" ")])])]):_vm._e(),(
          _vm.contract.contractType === 'PL' || _vm.contract.contractType === 'TL'
        )?_c('b-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mt-2 px-1 py-1 card-custom bg-light-green"},[_c('div',{},[_c('p',{staticClass:"f-14 f-medium"},[_vm._v(" รวมเงินที่ต้องชำระทั้งสิ้น "),_c('span',{},[_vm._v("(บาท)")])])]),_c('p',{staticClass:"f-medium f-14 d-flex mt-3 f-orange"},[_vm._v(" "+_vm._s(_vm.contractInfo.totalNextPaymentAmount)+" ")])])]):_vm._e(),(
          (_vm.contract.contractType === 'HP' ||
            _vm.contract.contractType === 'FL') &&
          _vm.contractInfo.displayTax
        )?_c('b-col',{staticClass:"mt-2 px-1 pr-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"card-custom p-1"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v(" ค่าภาษี "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])])]),_c('p',{staticClass:"f-medium f-14 f-orange text-end"},[_vm._v(" "+_vm._s(_vm.contractInfo.taxPaymentAmount)+" ")])])]):_vm._e(),(
          (_vm.contract.contractType === 'HP' ||
            _vm.contract.contractType === 'FL') &&
          _vm.contractInfo.displayRI
        )?_c('b-col',{staticClass:"mt-2 px-1 pl-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"card-custom p-1"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v(" ค่าบริการ พ.ร.บ. "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])])]),_c('p',{staticClass:"f-medium f-14 f-orange text-end"},[_vm._v(" "+_vm._s(_vm.contractInfo.riPaymentAmount)+" ")])])]):_vm._e()],1),_c('p',{staticClass:"f-12 text-center mt-2"},[_vm._v(" *ยอดชำระค่างวดจะอัปเดตหลังจากวันชำระเงิน 2 วันทำการ ")]),_c('p',{staticClass:"f-12 text-center mt-2"},[_vm._v(" หากท่านต้องการปิดบัญชีก่อนกำหนด กรุณาสอบถามยอดปิดบัญชี โทร.  "),_vm._l(('1317'),function(c,i){return _c('span',{key:i,staticClass:"link-tel",class:[{ 'link-tel-green': c == '3' }, 'link-tel']},[_vm._v(_vm._s(c)),_c('span',{staticClass:"link-tel-green"})])})],2)],1):_vm._e(),(
      _vm.contract.collectionStatusUnits !== 'L' &&
      _vm.contract.collectionStatusUnits !== 'SP'
    )?_c('div',[_c('p',{staticClass:"mb-0 txt-note f-orange text-end"},[_vm._v(" ข้อมูล ณ วันที่ "+_vm._s(_vm.contractInfo.asOfDateDisplay || "-")+" "),_c('br')])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }