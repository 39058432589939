<template>
  <div>
    <p class="f-medium">สัญญาสินเชื่อของท่าน</p>
    <div class="px-1">
      <div class="justify-content-between d-flex f-14">
        <p>เลขที่สัญญา :</p>
        <p class="f-medium">{{ contract.contractNoDisplay }}</p>
      </div>

      <div
        class="f-14"
        v-if="contract.contractType !== 'PL' && contract.contractType !== 'TL'"
      >
        <p class="f-medium">ประเภทการชำระ :</p>
        <InputSelect
          valueFiedl="value"
          textField="name"
          :options="paymentOptions"
          v-model="selectedPayment"
          @onDataChange="handlePaymentChange"
          ><template v-slot:option-first>
            <b-form-select-option value="" disabled>
              -- เลือกประเภทการชำระ --
            </b-form-select-option>
          </template></InputSelect
        >
      </div>

      <div class="justify-content-between d-flex f-14">
        <p class="text-nowrap">ยอดเงินที่ต้องชำระ :</p>
        <p
          class="f-medium text-end"
          :class="{
            'f-orange': ['SP', 'L'].includes(contract.collectionStatusUnits)
          }"
        >
          {{
            !["SP", "L"].includes(contract.collectionStatusUnits)
              ? totalAmount
              : "ตามที่ตกลงกับเจ้าหน้าที่"
          }}
        </p>
      </div>

      <div class="barcode-container px-4 f-12 text-center mt-2">
        <div
          class="spinner-border mt-2 text-center"
          role="status"
          v-if="loading"
        >
          <div class="sr-only">Loading...</div>
        </div>
        <div v-else>
          <p class="font-weight-bold">บาร์โคด/คิวอาร์โคด ชำระเงินของท่าน</p>
          <p>รหัสอ้างอิง 1/ Ref.1: {{ barcodeImg.ref1 }}</p>
          <div class="mt-2">
            <img
              :src="barcodeImg.filePathbarcodeNotFlip"
              alt="barcode"
              class="barcode-size"
              @click="
                openImageModal(barcodeImg.filePathbarcodeNotFlip, 'normal')
              "
            />
          </div>

          <p class="f-10">{{ barcodeImg.barcodeBankText }}</p>
          <div>
            <img
              :src="barcodeImg.filePathQrCode"
              alt="qr-code"
              class="qr-size mt-2"
              @click="openImageModal(barcodeImg.filePathQrCode, 'Qr')"
            />
          </div>
          <p class="f-10">{{ barcodeImg.qrCodeBankText }}</p>
        </div>
      </div>
    </div>
    <hr />
    <div class="barcode-container px-4 f-14 text-center mt-4">
      <p class="font-weight-bold">วิธีการชำระเงินด้วยบาร์โคด/คิวอาร์โคด</p>
      <p class="mt-2 f-12">
        หลังจากบันทึกภาพบาร์โคด/คิวอาร์โคดเรียบร้อยแล้ว <br />
        สามารถเลือกชำระเงินผ่านการสแกน<br />
        ตามช่องทางการชำระเงินด้านล่าง
      </p>

      <b-card class="bg-gray mt-2">
        <p>ชำระผ่านธนาคารที่ร่วมให้บริการ</p>
        <div class="bank-img-ciontainer d-flex justify-content-center mt-2">
          <a
            v-for="(option, index) in options"
            :key="index"
            :href="option.linkUrl"
            :alt="option.name"
          >
            <img :src="option.imageUrl" class="icon-bank px-1"
          /></a>
        </div>

        <p class="mt-2">ช่องทางการชำระอิ่นๆ</p>
        <div class="bank-img-ciontainer d-flex justify-content-center mt-2">
          <img
            v-for="(option, index) in otherOptions"
            :key="index"
            :src="option.imageUrl"
            :alt="option.name"
            class="icon-pay px-1"
          />
        </div>
      </b-card>
    </div>
    <b-modal ref="imageModal" size="md" centered hide-footer>
      <template #modal-title>
        <div class="d-flex justify-content-center ml-2">
          <p class="f-14">{{ textDownload }}</p>
          <!-- <font-awesome-icon
            class="icon-w f-green ml-2 mb-1"
            :icon="['fas', 'download']"
            @click="dowloadBarcode()"
          /> -->
        </div>
      </template>
      <img
        :src="selectedImage"
        class="w-100"
        :hide-footer="true"
        alt="expanded image"
      />
    </b-modal>
  </div>
</template>

<script>
import InputSelect from "../../../components/inputs/InputSelect.vue";

export default {
  components: { InputSelect },
  props: {
    profile: {
      required: false,
      type: Object
    },
    lineProfile: {
      required: false,
      type: Object
    },
    contract: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  data: () => {
    return {
      lineId: "",

      callCenter: "",

      paymentOptions: [],
      selectedPayment: "",
      paymentAmount: 0,
      state: "form",
      payPageHTML: "",
      pay_onlineResponse: {},
      numberRef: "",
      contractInfo: {},
      barcodeUrl: {},
      barcodeImg: {},
      options: [],
      otherOptions: [],
      contractNo: "",
      totalAmount: null,
      selectedImage: null,
      typeBarcode: "",
      textDownload: "",
      loading: false
    };
  },
  created() {},
  methods: {
    openImageModal(imageSrc, typeBarcode) {
      this.selectedImage = imageSrc; // ตั้งค่า src ของรูปภาพ
      this.typeBarcode = typeBarcode;
      if (typeBarcode == "Qr") {
        this.textDownload = "บันทึกภาพหน้าจอคิวอาร์โคดชำระเงิน";
      } else {
        this.textDownload = "บันทึกภาพหน้าจอบาร์โคดชำระเงิน";
      }
      this.$refs.imageModal.show(); // เปิด modal
    },
    async getBarcodePayment() {
      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          ContractNo: this.contract.contractNo
        }),
        Path: `${this.$API_DEV_SKL}/contract_installment`,
        Method: "POST"
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      var myHeadersBankList = new Headers();
      myHeadersBankList.append("API-KEY", "S1112LIN05!");

      var requestOptionsBankList = {
        method: "GET",
        headers: myHeadersBankList,
        redirect: "follow"
      };

      try {
        const [contractResult, bankResult, otherBankResult] = await Promise.all(
          [
            fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions).then(
              response => response.json()
            ),
            fetch(
              `${this.$API_DEV_LINE_SKL}/Bank/OnlinePayment`,
              requestOptionsBankList
            ).then(response => response.json()),
            fetch(
              `${this.$API_DEV_LINE_SKL}/Bank/OnlinePayment/others`,
              requestOptionsBankList
            ).then(response => response.json())
          ]
        );

        if (contractResult.result) {
          this.contractInfo = contractResult.data;
        }

        if (bankResult.result == 1) {
          this.options = bankResult.detail;
        }

        if (otherBankResult.result == 1) {
          this.otherOptions = otherBankResult.detail;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getContactBarcodeImg(contractNo, contractType) {
      this.loading = true;
      await this.getBarcodePayment();

      this.contractNo = contractNo;
      // await this.getPaymentAmount(contractNo);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);

      var raw = JSON.stringify({
        ContractNo: contractNo,
        LineId: this.lineProfile.userId,
        trancode: this.selectedPayment
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(
        `${this.$API_DEV_LINE_SKL}/payment/QRCodeByTranCode`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.barcodeImg = result.detail;
          } else {
            this.barcodeImg = {};
          }
        })
        .catch(error => {
          console.error(error);
        });
      this.loading = false;
    },
    async getPaymentAmount(contract, contractType) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      var raw = JSON.stringify({
        ContractNo: contract,
        LineId: this.lineProfile.userId,
        trancode: this.selectedPayment
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${this.$API_DEV_LINE_SKL}/payment/Amount`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.totalAmount = result.detail.amount;
            this.paymentOptions = result.detail.paymentOptions;
            this.selectedPayment = result.detail.tranCode;
            this.getContactBarcodeImg(contract);
          } else {
            this.totalAmount = "0.00";
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    handlePaymentChange(val) {
      this.selectedPayment = val;
      this.getPaymentAmount(this.contractNo);
    },
    dowloadBarcode() {
      const imageUrl = this.selectedImage;
      let fileName = imageUrl.split("/")[imageUrl.split("/").length - 1];
      console.log(fileName);
      // ใช้ Fetch API เพื่อดึงรูปภาพ และบังคับให้ดาวน์โหลด
      fetch(imageUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error(`Failed to fetch image: ${response.statusText}`);
          }
          return response.blob(); // แปลงข้อมูลเป็น Blob
        })
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob); // สร้าง Blob URL
          const anchor = document.createElement("a");
          anchor.href = blobUrl;
          anchor.download = fileName; // ตั้งชื่อไฟล์ที่ต้องการบันทึก
          document.body.appendChild(anchor);
          anchor.click(); // บังคับคลิก
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(blobUrl); // ลบ Blob URL เพื่อลดการใช้หน่วยความจำ
        })
        .catch(error => {
          console.error("Error while downloading image:", error);
        });

      // const imageUrl = "https://dev-master-chatbot-image-api.d-dots.com/dev-master-chat-agent/NoteImg/images/244922094929158.png";
      // console.log(imageUrl);
      // let fileName = imageUrl.split("/")[imageUrl.split("/").length - 1];
      // let img = new Image();
      // img.src = imageUrl;
      // img.crossOrigin = "Anonymous";
      // img.onload = () => {
      //   console.log("Image loaded");
      //   let canvas = document.createElement("canvas");
      //   canvas.width = img.width;
      //   canvas.height = img.height;
      //   let ctx = canvas.getContext("2d");
      //   ctx.drawImage(img, 0, 0);
      //   let dataURL = canvas.toDataURL();

      //   let fileLink = document.createElement("a");

      //   fileLink.href = dataURL;
      //   fileLink.setAttribute("download", `${fileName.split("/").pop()}`);
      //   document.body.appendChild(fileLink);

      //   fileLink.click();
      // };
    }
  }
};
</script>

<style scoped>
.barcode-size {
  width: 100%;
  height: 60px;
}

.qr-size {
  width: 60px;
  height: 60px;
}

.f-10 {
  font-size: 10px !important;
}

.bg-gray {
  background-color: rgb(231, 231, 231);
}

.icon-bank {
  height: 40px;
  /* border-radius: 50%; */
  background-image: none !important;
}

@media (max-width: 576px) {
  .icon-bank {
    height: 30px !important;
  }

  .icon-pay {
    height: 30px !important;
  }
}

.icon-pay {
  height: 40px;
  border-radius: 50%;
}

::v-deep .modal-title {
  display: flex;
  margin-left: 18px;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}

::v-deep .modal-header {
  padding: 12px !important;
}
</style>
